import React from 'react';

import productImg1 from '../../img/products/10SFP+/Dual_5.jpg'

import TransceiverBlock from '../../img/products/10SFP+/Transciever.jpg';
import pinassisment from '../../img/products/10SFP+/Tunable1_pinassigement.jpg';
import machdimenssion from '../../img/products/10SFP+/DWDM_Dimension.jpg';
import Memorymap from '../../img/products/2.5_SFP/CWDM_MemoryMap.jpg';
// import Memorymap2 from '../../img/products/1.25G CSFP/memoryMap2.png';

import Navbar from '../../Navbar';
import Footer from '../../Footer';

import { BsCartCheck } from 'react-icons/bs';
import { LiaWarehouseSolid } from 'react-icons/lia';
import { MdSecurity } from 'react-icons/md';

import { Link } from 'react-router-dom';


// photo swaping 
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import EnquireForm from '../EnquireForm';

const Dual_H7_S15_80DQ = () => {

    let PartNumber = "NXOSPP-H7-S15-80DQ";
    let productName = "10G SFP+ ZR Transceiver Hot Pluggable, Duplex LC, 1550nm EML, SMF 80KM, DDM, Multi-Rate";

    
    return (
        <>
            <Navbar />

            <div className='product-struct'>
                <div className='productImgLightBox'>

                    <div className='LightBox'>

                        <div className='d-flex justify-content-center' style={{ width: "100%" }}>
                            <img src={productImg1} className='img-main' alt="product-40GLoobBack" />

                        </div>

                        <p className='text-center' style={{ color: "gray", fontWeight: "200", fontSize: "15px", marginBottom: "0px" }}>Click to open expanded view</p>

                        <div className="d-flex justify-content-center">
                            <Gallery>


                                <Item
                                    original={productImg1}
                                    thumbnail={productImg1}
                                    width="1024"
                                    height="768"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} src={productImg1} className='lightbox-img' alt='img-not-found'/>
                                    )}
                                </Item>

                                <Item
                                    original={productImg1}
                                    thumbnail={productImg1}
                                    width="1024"
                                    height="768"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} src={productImg1} className='lightbox-img' alt='img-not-found'/>
                                    )}
                                </Item>

                                <Item
                                    original={productImg1}
                                    thumbnail={productImg1}
                                    width="1024"
                                    height="768"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} src={productImg1} className='lightbox-img' alt='img-not-found'/>
                                    )}
                                </Item>

                                <Item
                                    original={productImg1}
                                    thumbnail={productImg1}
                                    width="1024"
                                    height="768"
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} src={productImg1} className='lightbox-img' alt='img-not-found'/>
                                    )}
                                </Item>

                            </Gallery>
                        </div>


                        <div className="d-flex justify-content-center flex-wrap cart-btn">
                            <Link className='btn-buy_now' data-bs-toggle="modal" data-bs-target="#myModal2"><BsCartCheck style={{ marginBottom: "5px" }} /> Request a Quote</Link>
                        </div>

                    </div>

                </div>

                <div className='product-content'>
                    <h4>
                        {productName}
                    </h4>

                    <div className='pricing-section d-flex flex-wrap align-items-center justify-content-between mt-4 mb-2'>
                        <p>Part Number</p>

                        <p className='sell-text'>{PartNumber}</p>
                    </div>

                    <div className='sub-content d-flex justify-content-start'>
                        <p style={{ fontWeight: "500" }}><LiaWarehouseSolid />  In Stock</p> &nbsp;&nbsp;&nbsp;&nbsp;
                        <p style={{ fontWeight: "500" }}><MdSecurity /> Test Support</p>
                    </div>

                    <h5>Features</h5>


                    {/* <p className='listFeture'>Compatible with IEEE802.3ae 10GBASE-ZR/ZW
 </p>

   <p className='listFeture'> Compliant with OBSAI 1536~6144Mbps, CPRI Option 2~8

</p>
   <p className='listFeture'>     Compliant with SFF-8431 SFP+ MSA </p>
   <p className='listFeture'>     Support 1.0625Gb/s to 11.32Gb/s Multi-Rate   </p>
  

   <p className='listFeture'>     Hot Pluggable </p>
   <p className='listFeture'>   1550nm EML laser transmitter
</p>
   <p className='listFeture'>     Duplex LC connector </p>
   <p className='listFeture'>     2-wire interface for management and diagnostic monitor compliant with SFF-8472 </p>
   <p className='listFeture'>     Single +3.3V power supply </p>
   <p className='listFeture'>     Link distance 40km over SM fiber </p>
   <p className='listFeture'>     RoHS Compliant </p>
 */}

                    <p className='listFeture'>   Compliant with IEEE802.3ae 10GBASE-ZR/ZW</p>
                    <p className='listFeture'>   Compliant with CPRI Option 8</p>
                    <p className='listFeture'>   Compliant with SFF-8431 SFP+ MSA</p>
                    <p className='listFeture'>   Support 9.953Gb/s to 11.32Gb/s Multi-Rate</p>
                    <p className='listFeture'>   Built-in TX CDR and RX CDR</p>
                    <p className='listFeture'>   Hot Pluggable</p>
                    <p className='listFeture'>   1550nm EML laser transmitter</p>
                    <p className='listFeture'> APD receiver</p>
                    <p className='listFeture'>   Duplex LC connector</p>
                    <p className='listFeture'>   2-wire interface for management and diagnostic monitor compliant with SFF-8472</p>
                    <p className='listFeture'>   Single +3.3V power supply</p>
                    <p className='listFeture'>   Link distance 80km over SM fiber</p>
                    <p className='listFeture'>   RoHS Compliant</p>

                    <h5 className='pt-5'>Applications</h5>

                    <p className='listFeture'>    10GBASE-ER/EW Ethernet @10.3125G </p>
                    <p className='listFeture'>    Fiber Channel 800-SM-LC-L 8GFC @8.5G, 1200-SM-LL-L 10GFC @10.51875G
                </p>
                    <p className='listFeture'>    SONET OC-192 & SDH STM-64 @9.953G </p>
                    <p className='listFeture'>    CPRI Option #7 @9.83G, #8  @10.1376G </p>
                    <p className='listFeture'>   OTN OTU2 @10.7G, OTU2e @11.09G 
                </p>
        


                    {/* <p className='listFeture'>  10GBASE-ER/EW Ethernet @10.3125G </p>
                    <p className='listFeture'>  Gigabit Ethernet @1.25G </p>
                    <p className='listFeture'> Fiber Channel 1GFC @1.0625G,
                        2GFC @2.125G, 4GFC @4.25G, 8GFC @8.5G,
                        10GFC @10.51875G </p>
                    <p className='listFeture'>  SONET OC-192 & SDH STM-64 @9.953G </p>
                    <p className='listFeture'> SONET OC-48 & SDH STM-16 @2.488G </p>
                    <p className='listFeture'>  OBSAI @6.144G / 3.072G / 1.536G </p>
                    <p className='listFeture'> CPRI @10.1376G / 9.8304G / 6.144G / 4.9152G
                        / 3.072G / 2.4576G / 1.2288G </p>
                    <p className='listFeture'>  OTN OTU2 @10.7G, OTU2e @11.09G, </p>
                    <p className='listFeture'>  OTU2f @11.32G </p> */}

                </div>

            </div>

            <div className='container pt-lg-5  pb-lg-5'>
                <div className='resp-overflow'>
                    <div className='d-flex justify-content-center'>
                        <ul className="navs nav-pills" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="pill" href="#home">Overview</a>
                            </li>



                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="pill" href="#menu1">Absolute Maximum Ratings</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="pill" href="#menu2">Recommended Operating Conditions</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="pill" href="#menu3">Digital Diagnostic Functions</a>
                            </li>

                        </ul>
                    </div>
                </div>



                <div className="tab-content">
                    <div id="home" className="container tab-pane active"><br />
                        <div className="container mt-3">
                            <h2>Overview</h2>

                            <p>

                                {PartNumber} Small Form Factor Pluggable
SFP+ transceivers are compliant with the current SFP+ Multi-Source Agreement (MSA) Specification. The high performance cooled 1550nm EML transmitter and
high sensitivity APD receiver provide superior
performance for 10GBASE-ZR/ZW applications up to SMF 80km optical links. And it is equipped with internal TX and RX retime units (Clock and Data Recover, CDR) to comply SONET/SDH jitter requirements and to enhance host card’s high-speed data signal integrity.



                            </p>
                        </div>
                    </div>

                    <div id="menu1" className="container tab-pane fade"><br />
                        <div className="container mt-3">
                            <h2>Absolute Maximum Ratings</h2>

                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Parameters</th>
                                            <th>Symbol</th>
                                            <th>Min.</th>
                                            <th>Max.</th>
                                            <th>Unit</th>


                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>Storage Temperature</td>
                                            <td>TST</td>
                                            <td>-40</td>
                                            <td>+85</td>
                                            <td>°C</td>
                                        </tr>

                                        <tr>
                                            <td>Storage Relative Humidity</td>
                                            <td>RH</td>
                                            <td>5</td>
                                            <td>95</td>
                                            <td>%</td>
                                        </tr>

                                        <tr>
                                            <td>Supply Voltage</td>
                                            <td>Vcc</td>
                                            <td>-0.5</td>
                                            <td>+4.0</td>
                                            <td>V</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div id="menu2" className="container tab-pane fade"><br />
                        <div className="container mt-3">
                            <h2>Recommended Operating Conditions</h2>

                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Parameters</th>
                                            <th>Symbol</th>
                                            <th>Min.</th>
                                            <th>Typ.</th>
                                            <th>Max.</th>
                                            <th>Unit</th>


                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>Case Operating Temp. (NXOSPP-H7-S15-80DQ)</td>
                                            <td>Top</td>
                                            <td>0</td>
                                            <td>-</td>
                                            <td>+70</td>
                                            <td>°C</td>
                                        </tr>

                                        <tr>
                                            <td>Case Operating Temp. (NXOSPP-H7-S15-80DQi)</td>
                                            <td>Top</td>
                                            <td>-40</td>
                                            <td>-</td>
                                            <td>+85</td>
                                            <td>°C</td>
                                        </tr>

                                        <tr>
                                            <td>Supply Voltage</td>
                                            <td>Vcc</td>
                                            <td>+3.13</td>
                                            <td>+3.3</td>
                                            <td>+3.47</td>
                                            <td>V</td>
                                        </tr>

                                        <tr>
                                            <td>Supply Current (NXOSPP-H7-S15-80DQ)</td>
                                            <td>Icc</td>
                                            <td></td>
                                            <td></td>
                                            <td>520</td>
                                            <td>mA</td>
                                        </tr>

                                        <tr>
                                            <td>Supply Current (NXOSPP-H7-S15-80DQi)</td>
                                            <td>Icc</td>
                                            <td></td>
                                            <td></td>
                                            <td>650</td>
                                            <td>mA</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div id="menu3" className="container tab-pane fade"><br />
                        <div className="container mt-3">
                            <h2>Digital Diagnostic Functions</h2>

                            <p>As defined by the SFP MSA (SFF-8472)  NXO SFP+ transceivers provide digital diagnostic functions via a 2-wire serial interface, which allows real-time access to the following operating parameters</p>
                            <ul>
                                <li>Transceiver temperature</li>
                                <li>Laser bias current</li>
                                <li>Transmitted optical power</li>
                                <li>Received optical power</li>
                                <li>Transceiver supply voltage</li>
                            </ul>

                            <p>It also provides a sophisticated system of alarm and warning flags, which may be used to alert end-users when particular operating parameters are outside of a factory-set normal range.
                            </p>

                            <p>
                                The operating and diagnostics information is monitored and reported by a Digital Diagnostics Controller (DDC) inside the transceiver, which is accessed through the 2-wire serial interface. When the serial protocol is
                                activated, the serial clock signal (SCL pin) is generated by the host. The positive edge clocks data into the SFP transceiver into those segments of its memory map that are not write-protected. The negative edge clocks data from the SFP transceiver. The serial data signal (SDA pin) is bi-directional for serial data transfer. The host uses SDA in conjunction with SCL to mark the start and end of serial protocol activation. The memories are organized as a series of 8-bit data words that can be addressed individually or sequentially.
                            </p>

                            <p><b>For more detailed information including memory map definitions, please see the SFP MSA (SFF-8472)
                                Specification.
                            </b></p>

                        </div>
                    </div>

                </div>
            </div>

            <div className='container pt-lg-2 pb-lg-5 pt-2 pb-2'>
  <h1 className='pb-4'>Transceiver Block Diagram </h1>

<div className='d-flex justify-content-center' style={{width:"100%"}}>
<img src={TransceiverBlock} className='sectionImg2_1' alt="Transceiver Block Diagram"/>
</div>

</div>

            {/* Memory Map Section Start */}
            <div className='container pt-lg-2 pb-lg-5 pt-2 pb-2'>
                <h1 className='pb-4'>Pin Assignment</h1>

                <div className='d-flex justify-content-center' style={{ width: "100%" }}>
                    <img src={pinassisment} className='sectionImg2_1' alt="memorymapImg" />
                </div>

            </div>

            <div className='container pt-lg-2 pb-lg-5 pt-2 pb-2'>
                <h1 className='pb-4'>Digital Diagnostic Memory Map</h1>

                <div className='d-flex justify-content-center' style={{ width: "100%" }}>
                    <img src={Memorymap} className='sectionImg2_1' alt="memorymapImg" />
                </div>
                <br />
                <br />
                {/* <div className='d-flex justify-content-center' style={{width:"100%"}}>
<img src={ Memorymap2 } className='sectionImg2_1' alt="memorymapImg"/>
</div> */}

            </div>


            {/* Mechanical Dimensions section start */}

            <div className='container pt-lg-2 pb-lg-5 pt-2 pb-2'>
                <h1 className='pb-4'>Mechanical Dimensions </h1>

                <div className='d-flex justify-content-center' style={{ width: "100%" }}>
                    <img src={machdimenssion} className='sectionImg2' alt="Transceiver Block Diagram" />
                </div>

            </div>
            {/* Mechanical Dimensions section end */}



            <Footer />
            {/* --------------------------------------------------------------------------------------------------------- */}
<EnquireForm pathNum={PartNumber} productN={productName}></EnquireForm>
    
        </>
    )
}

export default Dual_H7_S15_80DQ;